const BOOKS = {
	workers: {
		title: 'Все сотрудники',
		endpoint: '/worker/all',
		path: 'workers',
		left: ['surname', 'name', 'middleName'],
		type: 'worker'
	},
	earners: {
		title: 'Все добытчики',
		slim: 'Рыбаки',
		endpoint: '/boardCompany/all',
		path: 'earners',
		left: ['name'],
		leftSub: ['phone'],
		type: 'board_company',
		report: 'ActionsFisherMen',
		reportTitle: 'Выберите сотрудника и укажите даты'
	},
	ships: {
		title: 'Все плашкоуты',
		slim: 'Плашкоуты',
		endpoint: '/receptionPoint/all',
		path: 'ships',
		left: ['name'],
		leftSub: ['factoryName'],
		leftSubTitle: 'НРС:',
		type: 'reception_point',
		report: 'ActionsReceptionPoint',
		reportTitle: 'Укажите даты'
	},
	transport: {
		title: 'Все ПТС',
		slim: 'ПТС',
		endpoint: '/receivingTransportShip/all',
		path: 'transports',
		left: ['name'],
		leftSub: ['phone'],
		type: 'receiving_transport_ship',
		report: 'ActionsRTS',
		reportTitle: 'Укажите даты'
	},
	factories: {
		title: 'Заводы',
		slim: 'Заводы',
		endpoint: '/factory/all',
		path: 'factories',
		left: ['name'],
		leftSub: ['phone'],
		type: 'factory',
		report: 'ActionsFactory',
		reportTitle: 'Укажите даты'
	},
	licenses: {
		title: 'Все разрешения на добычу',
		endpoint: '/fishingLicense',
		path: 'licenses',
		left: ['number'],
		type: 'licenses'
	},
	tariffs: {
		title: 'Тарифы',
		endpoint: '/dictionary/fishPrice',
		path: 'tariffs',
		left: ['number'],
		type: 'tariffs'
	},
	boxes: {
		title: 'Ящики и веса',
		endpoint: '/box/getAllBox/',
		path: 'boxes',
		left: ['number'],
		type: 'boxes'
	},
	journals: {
		title: 'Промысловый журнал',
		slim: 'Промысловый журнал',
		endpoint: '/fishingLicense',
		path: 'journals',
		left: ['number'],
		type: 'journals',
		report: 'FishingBookPeriod',
		reportTitle: 'Укажите период'
	},
	cams: {
		title: 'Видеоконтроль',
		endpoint: '/cameraEvents/listEvents',
		path: 'cams',
		left: ['number'],
		type: 'cams'
	},
	regions: {
		title: 'Рыболовные участки',
		endpoint: '/dictionary/fisherRegionPageable',
		path: 'regions',
		left: ['name'],
		leftSub: ['nameShort'],
		type: 'regions'
	}
}

if (JSON.parse(localStorage.account).roles.ADMIN) {
	BOOKS.organizations = {
		title: 'Организации',
		endpoint: '/organization',
		path: 'organizations',
		left: ['name'],
		leftSub: ['inn'],
		type: 'organization'
	}
}

export default BOOKS
