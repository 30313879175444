<template>
	<header>
		<h1 class="title mr-3">
			{{ tariffs.title }}
		</h1>
	</header>
	<ul class="list">
		<li class="list__element pointer" v-for="item in list" :key="item.id">
			<router-link :to="`/tariffs/${item.id}`">
				<p>
					<strong>Действует</strong> <br>
					от: {{ getInputDate(new Date(item.dateStart*1000)) }} <br>
					до: {{ getInputDate(new Date(item.dateEnd*1000)) }}
				</p>
				<p>
					<strong>Вид рыбы:</strong> {{ item.fish.name }}
				</p>
				<p>
					<strong>Сорт рыбы:</strong> {{ item.fishSort.name }}
				</p>
				<p>
					<strong>Цена:</strong> {{ numberFormat(item.price) }} р.
				</p>
			</router-link>
		</li>
	</ul>
	<BasePagination
		v-model:value="pagination.page"
		:max="pagination.totalPages"
		@update:value="getData"
	/>
	<div class="add">
		<IconPlus @click="$router.push(`/tariffs/new`)" color="white" :diameter="32" circle />
	</div>

</template>

<script>
import BOOKS from '@/modules/constants/books'

import { onMounted, reactive, ref } from 'vue'
import { useApi } from '@/modules/api'

import BasePagination from '@/components/ui/BasePagination'
import IconPlus from '@/components/ui/icons/IconPlus'
import { getInputDate } from '@/utils/getInputDate'
import numberFormat from '@/utils/numberFormat'

export default {
	name: 'Licenses',
	components: { BasePagination, IconPlus },
	setup () {
		const { tariffs } = BOOKS
		const list = ref([])

		const pagination = reactive({
			show: true,
			page: 0,
			size: 20,
			totalPages: 0
		})

		const getData = async () => {
			const { get, data } = useApi(tariffs.endpoint)

			const urlParametrs = `?offset=0&page=${pagination.page}&size=${pagination.size}&sort=desc`

			await get(urlParametrs)

			const { content, totalPages } = data.value
			pagination.totalPages = totalPages

			list.value = content
		}

		onMounted(async () => {
			await getData()
		})

		return {
			tariffs,
			list,
			pagination,
			getData,
			getInputDate,
			numberFormat
		}
	}
}
</script>

<style lang="scss" scoped>
.list {
	margin-top: 20px;
	&__element {
		transition: background-color 0.3s ease;
		padding: 4px;
		display: grid;

		p {
			b {
				display: block;
				margin-bottom: 2px;
			}
		}

		@include until-mobile {
			p + p {
				margin-top: 4px;
			}
		}

		@include for-tablet {
			display: flex;
			justify-content: space-between;
			align-items: center;

			p {
				&:last-child {
					// text-align: right;
				}
			}

			&.hover {
				&:hover {
					background-color: rgba($bgMain, 0.1);
				}
			}
		}

		& + & {
			border-top: 1px solid $borderPrimary;
		}
	}
}
.add {
	position: fixed;
	top: 60px;
	right: 20px;
}
</style>
